<template>
  <div class="main">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>用户收货地址列表</span>
        <div style="margin-top: 18px; display: flex; flex-wrap: wrap">
          <div class="item">
            <span class="label">注册手机号： </span>
            <el-input
              size="small"
              v-model="filterData.register_phone"
              placeholder="输入注册手机号搜索"
              clearable
              style="width: 240px"
            />
          </div>
          <div class="item">
            <span class="label">手机号： </span>
            <el-input
              size="small"
              v-model="filterData.phone"
              placeholder="输入手机号搜索"
              clearable
              style="width: 240px"
            />
          </div>
        </div>
        <div style="display: flex; flex-wrap: wrap; padding-left: 130px">
          <el-button
            size="small"
            type="primary"
            @click="query(1)"
            icon="el-icon-search"
          >
            查询
          </el-button>
          <el-button
            size="small"
            @click="filterData = { page: 1 }"
            icon="el-icon-plus"
          >
            重置
          </el-button>
        </div>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
        size="mini"
        :cell-style="{ paddingTop: '12px', paddingBottom: '10px' }"
      >
        <el-table-column label="注册手机号">
          <template slot-scope="scope">
            {{ (scope.row.user || {}).phone }}
          </template>
        </el-table-column>
        <el-table-column prop="first_name" label="姓名" />
        <el-table-column label="地区">
          <template slot-scope="scope">
            {{ scope.row.province }} - {{ scope.row.city }} -
            {{ scope.row.county }}
          </template>
        </el-table-column>
        <el-table-column prop="detail" label="地址" />
        <el-table-column prop="phone" label="手机号" />
      </el-table>
      <el-row>
        <el-col :span="24" style="text-align: center; margin-top: 10px">
          <el-pagination
            :page-sizes="[10]"
            :page-size="10"
            background
            layout="total,sizes,prev, pager, next"
            @current-change="pageChange"
            :current-page="filterData.page"
            :total="total"
          ></el-pagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      total: 0,
      rule: [],
      filterData: { page: 1 },
      tableData: [],
      form: {},
    };
  },

  created() {
    this.query();
  },

  methods: {
    pageChange(page) {
      this.filterData.page = page;
      this.query();
    },
    query(page) {
      if (page) this.filterData.page = page;
      this.$http
        .post("/admin/stats/user_address", this.filterData)
        .then((res) => {
          if (res.status === 200) {
            this.tableData = res.data.data;
            this.total = res.data.total;
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.main /deep/ .el-date-editor .el-range-separator {
  box-sizing: content-box;
  width: 10%;
}
.label {
  white-space: nowrap;
  width: 120px;
  display: inline-block;
  text-align: right;
  margin-right: 10px;
}
.item {
  margin-bottom: 10px;
  margin-right: 10px;
}
</style>
